.weather {

    iframe {
        width: 100%;
        min-height: 250px;
    }

    a.button {
        float: right;
    }
}