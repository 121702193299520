.galeria {

    text-align: center;
    padding-bottom: 4em;
    margin-top: 2em;

    p {
        margin: 1em 0 2em 0;
    }

    .navigation {
        margin: 1em 0 3em 0;
    }

    .envira-gallery-image {
        border: 10px solid #FFFFFF;
    }

    .envira-gallery-wrap {        
        margin: 1em 0 3em 0;
    }

    /*
    #envirabox-wrap {
        #envirabox-title {
            visibility: hidden;
        }
    }
    */
}

// Overrides for mobile

@include breakpoint(mobile) {

    #envirabox-wrap {
        width:  100% !important;
        left:  0 !important;
        padding: 0 !important;
    }

    div#envirabox-content {
        width: 100% !important;
        height:  auto !important;
        border-width: 0 !important;
    }

    div#envirabox-outer {
        left:  0 !important;
    }

    a#envirabox-close {
        right: 0 !important;
    }

    div#envirabox-title {
        left: 50% !important;
        transform: translateX(-50%);
    }
}