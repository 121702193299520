.content {
    text-align: justify;

    b, strong {
        font-weight: 700;
    }

    ul {
        list-style: disc;
        margin-left: 3em;
    }    

	a:not(.no-underline) {
		color: #46bdd3;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
    }

    a.no-underline {
        text-decoration: none;
        color: inherit;
    }
}