a.house, a.house:link, a.house:visited, a.house:active {
    text-decoration: none;
    color: inherit;
    padding-bottom: 1em;
    
    img {
        border-color: rgb(235, 235, 235);
    }
}

section.house {    

    .row {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    h3 {
        padding-top: 2em;    
        margin-bottom: 1em;
        
        @include breakpoint(desktop) {
            font-size: 2em !important;
        }    
        
        @include breakpoint(tablet) {
            font-size: 1.8em !important;
        }

        @include breakpoint(mobile) {
            font-size: 1.5em !important;
        }
    }

    .house-image {
        vertical-align: top;
        margin-right: 2em;
        margin-bottom: 1em;
    }

    ul.house-data {
        vertical-align: top;
        display: inline-block;
        margin-top:0;
        padding-top: 0;
        margin-left: 1.5em;
        
        b {
            font-weight: 600;
        }

        a {
            color: #9b9fa5;

            &:hover {
                text-decoration: none;
            }
        }

        li {
            position: relative;
            padding: 1em 0em 1em 5em;
            border-top: 1px solid rgba(144, 144, 144, 0.25);
            line-height: 2em;  
            margin-top: 0;      

            &:first-child {
                padding-top: 0.2em;
                border-top: 0;
            }

            span {
                position: absolute;
                left: 0;
                top: 1.4em;
                font-size: 1.1em;

                &:before {
                    display: inline-block;
                    background: #46bdd3;
                    width: 43px;
                    height: 43px;
                    line-height: 43px;
                    text-align: center;
                    font-size: 20px;
                    color: #FFFFFF;
                }
            }

            &:first-child span {
                top: 0.65em;
            }
        }
    }

    ul.house-buttons {
        margin-bottom: 3em;
        
        li {
            margin-top: 0.75em;
        }
    }
}