	.more-stories {
        float: right;
        margin-top: 2em;
    }

    h3 {
        //text-align: center;
    }
    
    .archive-opowiesc {
        & > section > .row {
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }