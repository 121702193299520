.fb {
    margin: 0;
    position: relative;
    width: 110px;

    .fb-like {
        position: absolute !important;
        top: -20px;

        @include breakpoint(tablet){
            top: -16.5px;
        }
    }
}

.fb-link {
    top: 20px;
    position: absolute;
    left: 295px;

    @include breakpoint(tablet) {
        left: 275px;
    }

    i {
        color: #ffffff;
        font-size: 20pt;
    }
}