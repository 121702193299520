.slide-top {
    position: fixed;
    background-color: _palette('blue');
    right: 3em;
    bottom: 3em;
    text-align: center;
    z-index: 1000;
    opacity: 0;
    transition: height 0s, opacity 1s;
    height: 0;
    overflow: hidden;

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        width: 4em;
        height: 4em;
        padding-top: 1em;

        &::after {
            font-family: FontAwesome;
            content: "\f077";
            color: _palette('white');
            font-size: 2em;
        }
    }
}