.calendar-wrapper
{
    height: 300px;

    table.calendar
    {
        font-size:14px;
        margin: 0 auto;

        td
        {
            height:25px;
            width:24px;
            vertical-align:bottom;
            padding:3px 8px 3px 8px;
        }
    }

    .calendar-day
    {
        background-color:#488E4C;
        color:#DDD;
    }
    .calendar-day {
        a:link, a:visited, a:active {
            color:#000 !important;
        }
    }

    .calendar-day-head
    {
        color: rgb(155, 159, 165);
        background-color:rgb(71, 77, 85);
    }
    .monthHeader
    {
        font-size:22px;
    }
    .pastDay
    {
        background-color:#888;
    }
    .prereserved
    {
        background-color:#E4A001;
    }
    .reserved
    {
        background-color:#C0272D;
    }
    /*
    .low1, .low2
    {
        border-bottom:3px green solid;
    }
    .medium1, .medium2
    {
        border-bottom:3px yellow solid;
    }
    .high
    {
        border-bottom:3px red solid;
    }
    */
    .calendarKey p
    {
        width:200px !important;
    }
    .rfirst, .pfirst{
        background-image:url('../../images/first.png');
        color:#000 !important;
    }
    .rlast, .plast{
        background-image:url('../../images/last.png');
    }
    .r2p{
        background-image:url('../../images/r2p.png');
    }
    .p2r{
        background-image:url('../../images/p2r.png');
    }
    .double{
        background-image:url('../../images/double.png') !important;
    }
}