ul.languages {
    top: 20px;
    position: absolute;
    left: 345px;

    @include breakpoint(tablet) {
        left: 325px;
    }

    li {
        float: left;
        margin-right: 10px;
    }
}

ul.languages-mobile {
    position: absolute;
    top: 12px;
    right: 20px;

    li {
        float: left;
        margin-left: 20px;
    }
}