.reservation-form {
    input[type='text'], input[type = "email"] {
        width: 90%;
    }

    input[type='submit'] {
        float:right;
    }

    label.error {
        color: _palette('red');
    }

    label:not(.error)
    {
        margin-top: 0.4em;
    }
}

#totalPrice {
    color: _palette('red');
    font-size: 2em;
    padding-top: 1em;

    b {
        font-weight: 500;
    }

    small {
        font-size: 0.55em;
        color: _palette('dark');
    }
}

.pricelist {
    text-align: center;
    margin: 2em auto;
    width: 100%;

    .table {
        display: inline-block;
        font-weight: 500;

        tr {

            td {
                margin: 0.2em;
                background-color: _palette('dark');
                color: _palette('white');
                padding: 0.8em 2.5em;

                @include breakpoint(mobile)
                {
                    padding: 0.7em 0.8em;
                    font-size: 0.95em;
                }

                &.amount {
                    font-weight: 600;
                }
            }

            &:nth-of-type(even) {
                td {
                    background-color: _palette('blue');
                }
            }
        }
    }
}

.reservation-error-info {
    &, h3 {
        color: _palette('red') !important;
    }
}

.ui-datepicker-calendar
{
    .ui-state-disabled {
        opacity: 0.9 !important;
    }
    .calendar-day {
        a, span {
            background-color: _palette('green');
            color: _palette('black') !important;
            padding-right: 5px;
        }
    }
    .pastDay {
        a, span {
            background-color: _palette('gray') !important;
            background-image: none !important;
            color: _palette('silver');
        }
    }

    .reserved {
        a, span {
            background-color: _palette('red');
            color: _palette('white');
        }
    }
    .prereserved {
        a, span {
            background-color: _palette('orange');
            color: _palette('white');
        }
    }
    .rlast {
        a, span {
            background-image: url(../../images/last.png);
            background-position: top;
        }
    }
    .rfirst {
        a, span {
            background-image: url(../../images/first.png);
            background-position: top;
        }
    }
    .plast {
        a, span {
            background-image: url(../../images/last.png);
            background-position: top;
        }
    }
    .pfirst {
        a, span {
            background-image: url(../../images/first.png);
            background-position: top;
        }
    }
    .double {
        a, span {
            background-image: url(../../images/double.png) !important;
            background-position: top;
        }
    }
}