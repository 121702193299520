.reviews {
    .comment-form {
        margin: 2em auto;
        width: 90%;
        max-width: 600px;

        h3 {
            text-align: center;
        }

        label {
            font-weight: 300;
            display: block;
        }

        input[type = "text"], textarea {
            border: 1px solid #9b9fa5;
            width: 100%;
            font-family: 'Open Sans', sans-serif;
            padding: 0.2em 0.5em;
            color: #32373d;;
        }

        textarea {
            height: 6em;
        }

        .form-submit {
            text-align: right;
            margin-top: 2.5em;
        }
    }

    .comments-list {
        width: 80%;
        margin: 4em auto;
        font-size: 1em;

        .comment {
            margin-top: 2em;

            .text {
                margin-bottom: 1em;
                text-align: justify;
            }

            .author {
                font-weight: 600;
                margin-bottom: 0.3em;

                .icon {
                    margin-right: 0.5em;
                }

                .www {
                    color: #9b9fa5;
                    font-weight: normal;

                    a {
                        text-decoration: none;
                        color: inherit;
                    }
                }
            }

        }
    }
}